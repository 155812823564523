import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DameToken from '../src/tools/dameToken.js';
import escudo from '../src/imagenes/escudoIseP.png';
import Button from 'react-bootstrap/Button';

function Display() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [muestro, setMuestro] = useState(false);
  const [datosVarios, setDatosVarios] = useState(null);
  const [integridad, setIntegridad] = useState(false);
  const [mensaje, setMensaje] = useState('Cargando...');
  const consulta = useQuery();

  const verificoIntegridad = () => {
    const curso = consulta.get('curso');
    const ni = consulta.get('ni');
    if (curso && ni > 400000) {
      setIntegridad(true);
      return true;
    }
    setIntegridad(false);
    return false;
  };

  const certificadoModel = (curso, certificado) => {
    const certificadoFinal = {
      dniCertificado: certificado.dniCertificado,
      apellidoNombre: certificado.apellidoNombre,
      tituloCertificado: curso.tituloCertificado,
      tipoCapacitacion:curso.tipoCapacitacion,
      calidadDe:certificado.calidadDe,
      ciudadCertificado: curso.ciudadCertificado,
      fechaciudadCertificado: curso.fechaciudadCertificado,
      escudo1: curso.escudo1,
      escudo2: curso.escudo2,
      fechacursado: curso.fechacursado,
      cantidadHoras: curso.cantidadHoras,
      tipoHoras: curso.tipoHoras,
      sedeCertificado: certificado.sedeCertificado,
      resolucionCertificado: curso.resolucionCertificado,
      grado: certificado.grado,
      reginterno: curso.reginterno,
      fechaRealizado:curso.fechaRealizado,
      cantidadFirmas: curso.cantidadFirmas,
      firma1: curso.firma1,
      firmante1: curso.firmante1,
      firmante1jer: curso.firmante1jer,
      firmante1cargo: curso.firmante1cargo,
      firma2: curso.cantidadFirmas > 1 ? curso.firma2 : '',
      firmante2: curso.cantidadFirmas > 1 ? curso.firmante2 : '',
      firmante2jer: curso.cantidadFirmas > 1 ? curso.firmante2jer : '',
      firmante2cargo: curso.cantidadFirmas > 1 ? curso.firmante2cargo : '',
      firma3: curso.cantidadFirmas > 2 ? curso.firma3 : '',
      firmante3: curso.cantidadFirmas > 2 ? curso.firmante3 : '',
      firmante3jer: curso.cantidadFirmas > 2 ? curso.firmante3jer : '',
      firmante3cargo: curso.cantidadFirmas > 2 ? curso.firmante3cargo : '',
      firma4: curso.cantidadFirmas > 3 ? curso.firma4 : '',
      firmante4: curso.cantidadFirmas > 3 ? curso.firmante4 : '',
      firmante4jer: curso.cantidadFirmas > 3 ? curso.firmante4jer : '',
      firmante4cargo: curso.cantidadFirmas > 3 ? curso.firmante4cargo : '',
      firma5: curso.cantidadFirmas > 4 ? curso.firma5 : '',
      firmante5: curso.cantidadFirmas > 4 ? curso.firmante5 : '',
      firmante5jer: curso.cantidadFirmas > 4 ? curso.firmante5jer : '',
      firmante5cargo: curso.cantidadFirmas > 4 ? curso.firmante5cargo : ''
    };
    setDatosVarios(certificadoFinal);
    setMuestro(true);
  };

  const obtieneCertificado = async () => {
    const client = axios.create({
      baseURL: `https://wwwa.isepsantafe.net.ar/expedientes/certificados/api-devuelveCertificados?ni=${consulta.get('ni')}&curso=${consulta.get('curso')}&Ra@=${DameToken()}`
    });
    try {
      const { data } = await client.post('/');
      if (data && data.cursante !== 'Nada que mostrar') {
        certificadoModel(data.Curso, data.cursante);
      } else {
        setMuestro(false);
        setMensaje('Datos no válidos');
      }
    } catch (error) {
      console.error('Error fetching certificate data:', error);
      setMensaje('Error al obtener los datos con los valores aportados');
    }
  };

  useEffect(() => {
    if (verificoIntegridad()) {
      obtieneCertificado();
    }
  }, []);

  const imprime = () => {
    window.open(`/imprimeCertificado?ni=${consulta.get('ni')}&curso=${consulta.get('curso')}`);
  };

  return (
    <>
      {integridad ? (
        <>
          {muestro ? (
            <div style={{ textAlign: 'center', margin:"10px", border:"1px solid black", padding:"5px"}}>
              <img src={escudo} width="100px" alt="Escudo" />
              <h1>El Instituto de Seguridad Pública de la Provincia de Santa Fe</h1>
              <h3 style={{ color: 'green' }}>
                Ha VERIFICADO el presente certificado bajo los siguientes valores
              </h3>
              <h4>Cursante:<strong> {datosVarios.apellidoNombre}</strong></h4>
              <h4>Identificación: <strong>{datosVarios.dniCertificado}</strong></h4>
              <h4>Calidad de Participación: <strong>{datosVarios.calidadDe}</strong></h4>
              <h4>Tipo de Capacitacion: <strong>{datosVarios.tipoCapacitacion}</strong></h4>
              <h4>Título de la capacitacion obtenido: <strong>{datosVarios.tituloCertificado}</strong></h4>
             
              <h4>Carga Horaria: <strong>{datosVarios.cantidadHoras}</strong></h4>
              <h4>Fecha del curso: <strong>{datosVarios.fechaRealizado}</strong></h4>
              <Button variant="success" size="lg" onClick={imprime}>
                Imprimir o descargar
              </Button>
            </div>
          ) : (<>  <div style={{ textAlign: 'center', margin:"10px", border:"1px solid red", padding:"5px"}}><img src={escudo} width="120px" alt="Escudo" />
            <h1 style={{ textAlign: 'center', marginTop: '10px', color:"red"}}>{mensaje}</h1>
            <h7>comuniquese al area de Extención comunitaria "extensioncomunitaria@isepsantafe.edu.ar" si considera que es un error  </h7></div> </>
          )}
        </>
      ) : (
        <h1>Consulta no autorizada!!</h1>
      )}
    </>
  );
}

export default Display;
