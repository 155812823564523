import React, { useState,useEffect } from 'react';
import {Document, Page, View,Text, PDFViewer,Image, StyleSheet, pdf} from '@react-pdf/renderer';
import ImagenQR from '../src/tools/imagenQR.jsx';
import Logo from '../src/imagenes/logoISePGoyenechea.png'
import logoProvincia from '../src/imagenes/escudoIseP.png'
import textoIsep from '../src/imagenes/logoProvinciaISeP2024.png'
import axios from 'axios';
import DameToken from '../src/tools/dameToken.js'
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import bannerSuperior from '../src/imagenes/bannerSuperior.png'
import bannerInferior from '../src/imagenes/bannerInferior.png'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
 

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
function Constancia() {
  function formatToThreeDigits(number) {
    if (number < 1 || number > 100) {
     
       return number
    }
    return number.toString().padStart(3, '0');
}
  const { width, height } = useWindowSize();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const styles = StyleSheet.create({
    text: {
      fontFamily: 'Tahoma',
      fontSize: 12,
    },
  });
  const [Er,setEr]=useState(false)
  const [muestro,setMuestro]=useState(false)
  const [datosVarios,setDatosVarios]=useState(false)
  const [claseCertificad,setClaseCertificado]=useState()
  const consulta = useQuery();
  const [anio, setanio] = useState();
  const certificadoModel=(curso, certificado)=>
    {
     
    const certificadoFinal={
      "idCurso":formatToThreeDigits(curso.id),
      "idCursante":formatToThreeDigits(certificado.id),
    "dniCertificado":certificado.dniCertificado,
    "apellidoNombre":certificado.apellidoNombre,
   "reginterno":curso.reginterno,
   "claseCertificado":curso.claseCertificado,
    "tituloCertificado":curso.tituloCertificado,
    "ciudadCertificado":curso.ciudadCertificado,
    "fechaciudadCertificado":curso.fechaciudadCertificado,
   
    "organizadoPor":curso.organizadoPor,
    "tipoCapacitacion":curso.tipoCapacitacion,
    "fechaRealizado":curso.fechaRealizado,
    "escudo1":curso.escudo1,
    "escudo2":curso.escudo2,
    "fechacursado":curso.fechacursado,
    "cantidadHoras":curso.cantidadHoras,
    "tipoHoras":curso.tipoHoras,
    "sedeCertificado":certificado.sedeCertificado,
    "resolucionCertificado":curso.resolucionCertificado,
    "resolucionJPP":curso.resolucionJPP,

    "grado":certificado.grado,
    "calidadDe":certificado.calidadDe,
      
    "cantidadFirmas":curso.cantidadFirmas,
    "firma1":curso.firma1,        
    "firmante1":curso.firmante1,
    "firmante1jer":curso.firmante1jer,
    "firmante1cargo":curso.firmante1cargo, 
    "firma2":curso.cantidadFirmas>1?curso.firma2:"",
    "firmante2":curso.cantidadFirmas>1?curso.firmante2:"",
    "firmante2jer":curso.cantidadFirmas>1?curso.firmante2jer:"",
    "firmante2cargo":curso.cantidadFirmas>1?curso.firmante2cargo:"",  
    "firma3":curso.cantidadFirmas>2?curso.firma3:"", 
    "firmante3":curso.cantidadFirmas>2?curso.firmante3:"",
    "firmante3jer":curso.cantidadFirmas>2?curso.firmante3jer:"",
    "firmante3cargo":curso.cantidadFirmas>2?curso.firmante3cargo:"",
    "firma4":curso.cantidadFirmas>3?curso.firma4:"",
    "firmante4":curso.cantidadFirmas>3?curso.firmante4:"",
    "firmante4jer":curso.cantidadFirmas>3?curso.firmante4jer:"",
    "firmante4cargo":curso.cantidadFirmas>3?curso.firmante4cargo:"",
    "firma5":curso.cantidadFirmas>4?curso.firma5:"",
    "firmante5":curso.cantidadFirmas>4?curso.firmante5:"",
    "firmante5jer":curso.cantidadFirmas>4?curso.firmante5jer:"",
    "firmante5cargo":curso.cantidadFirmas>4?curso.firmante5cargo:""    
    }
    setDatosVarios(certificadoFinal)
    setMuestro(true)
    }
 


function fechaFormal() {
  const meses = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio", 
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  const hoy = new Date();
  const dia = hoy.getDate();
  const mes = meses[hoy.getMonth()];
  const anio = hoy.getFullYear();
//a los ${dia} días del mes de 
  return `${mes} de ${anio}`;
}
function strigAFecha(dateString) {

  const date = new Date(dateString);


  if (isNaN(date)) {
      throw new Error('Fecha inválida');
  }

  return date;
}

const reemplazarEspacios = (str) => {
  return str.replace(/ /g, '%20'); // Reemplaza todos los espacios
};
const direccion="https://certificados.isepsantafe.net.ar?ni="+consulta.get("ni")+"&curso="+reemplazarEspacios(consulta.get('curso'))+"&Ra@="+DameToken()



const obtieneCertificado=async()=>{
  const client = axios.create({
      baseURL:"https://wwwa.isepsantafe.net.ar/expedientes/certificados/api-devuelveCertificados?ni="+ consulta.get("ni")+"&curso="+consulta.get('curso')+"&Ra@="+DameToken()
      });
      try {
       const datos= await client.post('/')
       if(datos)
        {
          if(datos.data.Cursante!="Nada que mostrar"){
            setanio(datos.data.Curso.createdAt)
           certificadoModel(datos.data.Curso,datos.data.cursante)
           setClaseCertificado(datos.data.Curso.claseCertificado)
         
           setMuestro(true)
          }else{
           setEr(true)
          }
           
   
            }else{setEr(true)}
        }
      catch (error) {}
 
}

const obtenerAnio = (fecha) => {
  return new Date(fecha).getFullYear();
};



useEffect(() => {
  
  obtieneCertificado()
  return () => {
    
  };
}, []);


function verificarString(cadena) {

  if (cadena>6000000) {
    return "DNI";
  } else {
    return "N.I.";
  } 
}

const hoy=fechaFormal()
const text1=`Se le otorga el presente CERTIFICADO al`
const text2=` ${datosVarios.grado?datosVarios.grado:"ciudadano"} ${datosVarios.apellidoNombre} ${ verificarString(datosVarios.dniCertificado)+" Nro: " + datosVarios.dniCertificado} `
const text3=`por haber asistido ${datosVarios.tipoCapacitacion=="Jornada"||datosVarios.tipoCapacitacion=="Capacitación"?"a la " +datosVarios.tipoCapacitacion:"al "+datosVarios.tipoCapacitacion} de “${datosVarios.tituloCertificado}”, en calidad de “${datosVarios.calidadDe}”, organizado por ${datosVarios.organizadoPor}, dictado ${datosVarios.fechaRealizado}, con una duración de ${datosVarios.cantidadHoras}.`
const text4=`${datosVarios.ciudadCertificado}, ${hoy+""}`


const clase1 = () => (
  <Document  title={"Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre} onLoadError={console.error}>
        <Page size="A4" orientation='landscape' >
        <View style={{ width:"100%", marginTop:"20px",marginLeft:"10px",padding:"1px"}}>
        <View id="Logos"style={{ width:"100%", marginTop:"20px",  padding:"1px"}}>
        <Image style={{width:"850px", position:"absolute", marginLeft:"-15px", marginTop:"-42px"}}src={bannerSuperior}/> 
        <Image style={{width:"85px", position:"absolute",marginLeft:"90px", marginTop:"20px" }}src={logoProvincia}/> 
       
        <Image style={{width:"320px", position:"absolute", marginTop:"40px", marginLeft:"450px"}} src={textoIsep}/>

      
       </View>
       <Text style={{textAlign:"center",marginTop:"130px",paddingTop:"1px",fontSize:"32px",fontWeight:"bold"}}>C   E   R   T   I   F   I   C   A   D   O</Text>
        
        <View className='datosPersonales_legajo' style={{textAlign:"justify",marginLeft:"75px",marginRight:"85px",paddingTop:"1px",fontSize:"12px",lineHeight: "2"}}>


    <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"16px",lineHeight: "2", marginTop:"50px", color:"#333"}}>{text1}<Text style={{textAlign:"justify",fontSize:"18px", color:"black"}}>{text2}</Text>{text3}
    </Text>
    

    <Text style={{textAlign:"rigth",fontSize:"16px",lineHeight: "2"}}>{text4}</Text>
    <View id="QR"style={{width:"150px", position:"absolute", marginLeft:"600px",marginTop:"280px"}}>       
    <ImagenQR direccion={direccion} tamaño="110"/></View>
    </View>
    <View style={{fontWeight:"bold", textAlign:"center", fontSize:"6px" ,  position:"absolute",  marginTop:"550px"}}>
    <Text style={{fontSize:"8px",lineHeight: "2", marginLeft:"676px"}}>Nro. de Registro:{datosVarios.idCurso+"-"+datosVarios.idCursante+"-"+obtenerAnio(anio)}</Text>
    </View>
    <Image style={{width:"850px", position:"absolute", marginLeft:"-15px", marginTop:"565px"}}src={bannerInferior}/> 
    </View>

        </Page>
      </Document>
);
const clase2 = () => (

    <Document  title={"Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre} onLoadError={console.error}>
          <Page size="A4" orientation='landscape' >
          <View style={{ width:"100%", marginTop:"20px",marginLeft:"10px",padding:"1px"}}>
          <View id="Logos"style={{ width:"100%", marginTop:"20px",  padding:"1px"}}>
          <Image style={{width:"850px", position:"absolute", marginLeft:"-15px", marginTop:"-42px"}}src={bannerSuperior}/> 
          <Image style={{width:"85px", position:"absolute",marginLeft:"90px", marginTop:"20px" }}src={logoProvincia}/> 
         
          <Image style={{width:"320px", position:"absolute", marginTop:"40px", marginLeft:"450px"}} src={textoIsep}/>

        
         </View>
         <Text style={{textAlign:"center",marginTop:"130px",paddingTop:"1px",fontSize:"32px",fontWeight:"bold"}}>C   E   R   T   I   F   I   C   A   D   O</Text>
          
          <View className='datosPersonales_legajo' style={{textAlign:"justify",marginLeft:"75px",marginRight:"85px",paddingTop:"1px",fontSize:"12px",lineHeight: "2"}}>
  

      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"16px",lineHeight: "2", marginTop:"50px", color:"#333"}}>{text1}<Text style={{textAlign:"justify",fontSize:"20px", color:"black"}}>{text2}</Text>{text3}
      </Text>
      

      <Text style={{textAlign:"rigth",fontSize:"16px",lineHeight: "2"}}>{text4}</Text>
      <View id="QR"style={{width:"150px", position:"absolute", marginLeft:"600px",marginTop:"280px"}}>       
      <ImagenQR direccion={direccion} tamaño="110"/></View>
      </View>
      <View style={{fontWeight:"bold", textAlign:"center", fontSize:"6px" ,  position:"absolute",  marginTop:"550px"}}>
      <Text style={{fontSize:"8px",lineHeight: "2", marginLeft:"676px"}}>Nro. de Registro:{datosVarios.idCurso+"-"+datosVarios.idCursante+"-"+obtenerAnio(anio)}</Text>
      </View>
      <Image style={{width:"850px", position:"absolute", marginLeft:"-15px", marginTop:"565px"}}src={bannerInferior}/> 
      </View>

          </Page>
        </Document>
  
);
if (claseCertificad=="Clase1"){

var MyDocument = clase1;
}else{

var MyDocument = clase2;
}


  const generatePdfDocument = async () => {
   try {
    const nombreArchivo="Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre
    const doc =<MyDocument/>
    const blob = await pdf(doc).toBlob();
    saveAs(blob, nombreArchivo);
   } catch (error) {
    
   } 
  };
// Componente principal del visor PDF
return (<>
{<>

  
  {muestro?<> {width<480?<><div style={{textAlign:"center"}}>
          <img src={Logo} style={{width:"100px"}}></img>
          <h1>Descargue su certificado {datosVarios.apellidoNombre}</h1>
          <button className="btn btn-primary"onClick={generatePdfDocument}>DESCARGAR</button></div></>:
          <>
          <PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
        <MyDocument />
        </PDFViewer></>}</>:<>{Er?<><div style={{ textAlign: 'center', margin:"10px", border:"1px solid red", padding:"5px"}}><img src={logoProvincia} width="120px" alt="Escudo" />
            <h1 style={{ textAlign: 'center', marginTop: '10px', color:"red"}}>{"Error al obtener los datos o datos Invalidos"}</h1>
            <h7>comuniquese al area de Extención comunitaria "extensioncomunitaria@isepsantafe.edu.ar" si considera que es un error  </h7></div></>:""}</>}

  
</>}

      
      </>

    );
  }
export default Constancia
